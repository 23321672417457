import {
  ButtonStratto,
  FiltersStrattoAvailability,
  // UtilitiesStratto,
} from "../ComponentsUtilities";
import { makeStyles } from "@mui/styles";
import PropertyFloorplans from "./PropertyFloorplans";
import { Box, Grid, Stack } from "@mui/material";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import ModalItemFloorPlan from "./ModalItemFloorPlan";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteModal.svg";
import { useStrattoFiltersValues, useViewsStratto } from "../../hooks/hooksStratto";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { cleanStrattoFilters } from "../../store/todoStratto-actions";

// const arrNumber = [
//   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 1, 2,
//   3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 1, 2, 3, 4,
//   5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
// ];

export default function FloorPlansScreen() {
  const dispatch = useAppDispatch()
  const classes = useStyles();
  const [openModalFloorPlan, setOpenModalFloorPlan] = useState<boolean>(false);
  const [selectedApartment, setSelectedApartment] = useState<any>(null)
  const views = useViewsStratto();
  const availabilityStratto = useAppSelector((state)=>state.availabilityStratto)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bedrooms, bathrooms, floorplanType, prices, areas, level] = useStrattoFiltersValues()


  const filterObject = useMemo(()=>{
    return {
      bedrooms,
      bathrooms,
      floor: level,
      'floor_plan_code': floorplanType
    }
  },[bathrooms, bedrooms, floorplanType, level])


  const filterUnits = useCallback((list: any)=>{
    return list.filter((item: any)=>{
      return Object.entries(filterObject).every(([key, value])=>{
        if(!value) return true
        return item[key] === value
      })
    })
  },[filterObject])

  const listdata = useMemo(() => {
    const list = views[2]?.components[0]?.apartments?.find(
      (item: any) => item.floor_plan_name === availabilityStratto.floorplanType
    );
    if (list) {
      return list?.units;
    }
    let tempData: any[] = []
    views[2]?.components[0]?.apartments?.map((apartment: any) => {
      return apartment?.units.forEach((unit: any) => {
        tempData.push(unit)
      });
    })
    return tempData;
  }, [availabilityStratto.floorplanType, views]);

  useLayoutEffect(()=>{
    dispatch(cleanStrattoFilters())
  },[dispatch])

  return (
    <>
      {!openModalFloorPlan && (
        <>
          <div
            style={{
              backgroundColor: "#1a1919",
              width: "100%",
              height: "100%",
            }}
          >
            {/* <div className={classes.utilities}>
              <UtilitiesStratto />
            </div> */}
            <div
              style={{
                width: "100%",
              }}
            >
              <FiltersStrattoAvailability />
            </div>
            <div className={classes.containerFloorPlans}>
              <div className={classes.bodyFloorPlans}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    {filterUnits(listdata).map((e: any, index: number) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                        <div onClick={() => {
                          const selectedApartment = views[2]?.components[0]?.apartments.find((apartment: any)=>apartment.floor_plan_code?.toLowerCase() === e.floor_plan_code?.toLowerCase())
                          setOpenModalFloorPlan(true)
                          setSelectedApartment(selectedApartment)
                        }}>
                          <PropertyFloorplans
                            price={e.price}
                            key={e.number}
                            name={e.name}
                            bedroom={e.bedrooms}
                            bathroom={e.bathrooms}
                            areaTotal={e.area}
                            images={e.images}
                          />
                        </div>
                      </Grid>
                    ))}
                    {/* {views[2]?.components[0]?.apartments[2]?.units?.map((e: any) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div onClick={() => setOpenModalFloorPlan(true)}>
                          <PropertyFloorplans price={e.price} key={e.number} name={e.name} bedroom={e.bedrooms} bathroom ={e.bathrooms} areaTotal={e.area} />
                        </div>
                      </Grid>
                    ))} */}
                  </Grid>
                </Box>
              </div>
            </div>
            <Stack
              sx={{
                position: "absolute",
                bottom: "16px",
                width: "100%",
              }}
              justifyContent="center"
              alignItems="center"
            >
              <ButtonStratto name="Amenidades" path="amenities-broll" />
            </Stack>
          </div>
        </>
      )}
      {openModalFloorPlan && (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#000000E5",
            }}
          >
            <ModalItemFloorPlan selectedApartment={selectedApartment}/>

            <DeleteIcon
              className={classes.deleteBtn}
              onClick={() => setOpenModalFloorPlan(false)}
            />
          </div>
          {/* <div className={classes.utilities}>
            <UtilitiesStratto />
          </div> */}
        </>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  containerFloorPlans: {
    display: "block",
    width: "100%",
    height: "90%",
    overflow: "hidden",
    "@media (max-width: 960px)": {
      height: "100%",
    },
  },
  bodyFloorPlans: {
    margin: "80px 50px",
    paddingRight: "5px",
    overflowY: "scroll",
    height: "86%",
    "::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#F5F5F5",
    },

    "@media (max-width: 640px)": {
      margin: "20px",
    },
  },
  utilities: {
    "@media (max-width: 640px)": {
      position: "absolute",
      bottom: "50px",
      right: "12px",
    },
  },
  deleteBtn: {
    cursor: "pointer",
    position: "absolute",
    top: "20px",
    right: "10px",
    "&:hover": {
      opacity: "0.8",
    },
  },
}));
