import {  Stack } from "@mui/material";

import { useAppDispatch, useSvgType } from "../../hooks";
import { setSVGType } from "../../store/todo-actions";
import { setStrattoSvgType } from "../../store/todoStratto-actions";
import { useStrattoSvgType } from "../../hooks/hooksStratto";

type Props = {
  isStratto?: boolean
}

export const BottomFilters = ({isStratto = false}: Props) => {
  const dispatch = useAppDispatch();
  const svgType = useSvgType();
  const strattoSvgType = useStrattoSvgType()
  const onClick2D = () => {
    if(isStratto) 
      return dispatch(setStrattoSvgType("2d"))
    dispatch(setSVGType("2d"));
  };
  const onClick3D = () => {
    if(isStratto) 
      return dispatch(setStrattoSvgType("3d"))
    dispatch(setSVGType("3d"));
  };

  return (
    <Stack direction="row" spacing={6}>
      <Stack
        direction="row"
        alignItems="end"
        sx={{
          marginRight: "10px",
        }}
      >
        <button
          style={{
            height: "32px",
            borderRadius: "20px",
            width:  isStratto ? (strattoSvgType === "2d" ? "44px" : "58px") :  svgType === "2d" ? "44px" : "58px",
            backgroundColor: isStratto ? (strattoSvgType === "2d" ?  "#B4FFEE" : "#F6F6F6") :  svgType === "2d" ? "#B4FFEE" : "#F6F6F6",
            border: `1px solid ${ isStratto ? (strattoSvgType === "2d" ? "#167D77" : "#979797") : svgType === "2d" ? "#167D77" : "#979797"}`,
            fontWeight: isStratto ? (strattoSvgType === "2d" ? "700" : "400") :  svgType === "2d" ? "700" : "400",
            marginRight: "-24px",
            zIndex:  isStratto ? (strattoSvgType === "2d" ? "1" : "0") : svgType === "2d" ? "1" : "0",
            paddingRight:  isStratto ? (strattoSvgType === "3d" ? "20px" : "6px") :  svgType === "3d" ? "20px" : "6px",
            cursor: "pointer",
          }}
          onClick={onClick2D}
        >
          2D
        </button>
        <button
          style={{
            height: "32px",
            borderRadius: "20px",
            paddingLeft:isStratto ? (strattoSvgType === "2d" ? "20px" : "6px") : svgType === "2d" ? "20px" : "6px",
            width: isStratto ? (strattoSvgType === "3d" ? "44px" : "58px") : svgType === "3d" ? "44px" : "58px",
            backgroundColor: isStratto ? (strattoSvgType === "3d" ?  "#B4FFEE" : "#F6F6F6") : svgType === "3d" ? "#B4FFEE" : "#F6F6F6",
            border: `1px solid ${isStratto ? (strattoSvgType === "2d" ? "#167D77" : "#979797") : svgType === "2d" ? "#167D77" : "#979797"}`,
            fontWeight:isStratto ? (strattoSvgType === "3d" ? "700" : "400") : svgType === "3d" ? "700" : "400",
            zIndex: isStratto ? (strattoSvgType === "3d" ? "1" : "0") : svgType === "3d" ? "1" : "0",
            cursor: "pointer",
          }}
          onClick={onClick3D}
        >
          3D
        </button>
      </Stack>
    </Stack>
  );
};
