import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialAvailabilityStrattoState: any = {
  data: [],
  views: [],
  buttons: [],
  currentVideo: "https://athum.com/360/768/svgs/Availability02_.mp4",
  currentVideoType: "forward",
  currentView: 3,
  currentLocation: null,
  toggleAvailabilityModal: false,
  level: 0,
  svgType:"3d",
  units:[],
  availability: "all",
  selectedApartment: null,
  bedrooms: null,
  bathrooms: null,
  prices: null,
  floorplanType: "",
  areas: "",
};

export const availabilityStrattoSlice = createSlice({
  name: "availabilityStratto",
  initialState: initialAvailabilityStrattoState,
  reducers: {
    setAvailabilityStratto(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
      state.views = action.payload.views;
    },

    setCurrentVideoStratto(
      state,
      action: PayloadAction<{
        currentVideo: string | null;
        currentVideoType: "forward" | "rewind";
      }>
    ) {
      state.currentVideo = action.payload.currentVideo;
      state.currentVideoType = action.payload.currentVideoType;
    },
    setCurrentLocationViewStratto(
      state,
      action: PayloadAction<{ currentView: number }>
    ) {
      state.pastView = state.currentView;
      state.currentView = action.payload.currentView;
    },
    setCurrentLocationsStratto(
      state,
      action: PayloadAction<{ currentLocation: number }>
    ) {
      state.currentLocation = action.payload.currentLocation;
    },
    setCurrentAvailabilityModalToggle(
      state,
      action: PayloadAction<{ currentToggle: boolean }>
    ) {
      state.toggleAvailabilityModal = action.payload.currentToggle;
    },
    setStrattoLevel(state, action: PayloadAction<{ level: number }>) {
      state.level = action.payload.level;
    },
    setStrattoSvgType(
      state,
      action: PayloadAction<{ currentSvgType: string }>
    ) {
      state.svgType = action.payload.currentSvgType;
    },
    setStrattoUnits(state, action: PayloadAction<{ data: any }>) {
      state.units = action.payload.data;
    },
    setStrattoFilter(state, action: PayloadAction<{ availability: string }>) {
      state.availability = action.payload.availability;
    },
    setSelectedApartment(state, action: PayloadAction<{ apartment: any }>) {
      state.selectedApartment = action.payload.apartment;
    },
    setStrattoBedrooms(state, action: PayloadAction<{ bedrooms: number | null }>) {
      state.bedrooms = action.payload.bedrooms;
    },
    setStrattoBathrooms(state, action: PayloadAction<{ bathrooms: number | null }>) {
      state.bathrooms = action.payload.bathrooms;
    },
    setStrattoPrices(state, action: PayloadAction<{ prices: number }>) {
      state.prices = action.payload.prices;
    },
    setStrattoAreas(state, action: PayloadAction<{ areas: string | number }>) {
      state.areas = action.payload.areas;
    },
    setStrattoFloorPlanType(state, action: PayloadAction<{ floorplanType: string }>) {
      state.floorplanType = action.payload.floorplanType;
    },
    cleanStrattoFilters(state) {
      state.bedrooms = null;
      state.bathrooms = null;
      state.prices = null;
      state.areas = null;
      state.floorplanType = null;
      state.level = null;
    },
  },
});
