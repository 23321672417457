import { useMediaQuery } from "@mui/material";
import { ReactComponent as ZoomInIcon } from "../../assets/icons/zoomin.svg";
import { ReactComponent as ZoomOutIcon } from "../../assets/icons/zoomout.svg";
import { useStrattoSvgType } from "../../hooks/hooksStratto";

export const HandleZoomStratto: any = ({
  handleZoomIn,
  handleZoomOut,
}: any) => {
  const svgType = useStrattoSvgType();
  const mobile = useMediaQuery("(max-width:1260px)");
  return (
    !mobile && svgType === "3d" && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100px",
          flexDirection: mobile ? "column" : "row",
          zIndex: 1000,
          position:'absolute',
          right:'120px'
        }}>
        <span
          onClick={handleZoomIn}
          style={{
            cursor: "pointer",
          }}>
          <ZoomInIcon />
        </span>

        <div
          style={{
            margin: mobile ? "16px 0" : "0 20px",
            cursor: "pointer",
          }}
          onClick={handleZoomOut}>
          <ZoomOutIcon />
        </div>
      </div>
    )
  );
};
