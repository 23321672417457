import { useEffect, useMemo } from "react";

import { useCurrentViewStratto, useStrattoSvgType, useViewsStratto } from "../../hooks/hooksStratto";
import { InteractiveFloorStratto } from "../InteractiveFLoorStratto";
import { InteractiveFloorplanStratto2D } from "../InteractiveFloorPlanStratto2D";

interface IScale {
  scaleZoom?: string;
}

export const StrattoLocation = ({ scaleZoom }: IScale) => {
  const views = useViewsStratto();
  const svgs = useMemo(()=>{
    return views?.[3]?.components[0].svgs
  },[views])

  const stratto2dSvg = useMemo(()=>{
    return svgs?.[0].views[0].svg2d
  },[svgs])
  const currentViewStratto = useCurrentViewStratto()


  const strattoSvgType = useStrattoSvgType()


  const svg = useMemo(() => {
    let tempSvg;
    switch (currentViewStratto) {
      case 1:
        tempSvg = svgs?.[0].views[0].svg;
        break;
      case 2:
        tempSvg = svgs?.[1].views[0].svg;
        break;
      case 3:
        tempSvg = svgs?.[2].views[0].svg;
        break;
      case 4:
        tempSvg = svgs?.[3].views[0].svg;
        break;
    }

    return tempSvg;
  }, [currentViewStratto, svgs]);


  useEffect(() => {
    const svgEmbed: any = document.getElementsByClassName("injected-svg")[0];
    if (svgEmbed) {
      svgEmbed.setAttribute("preserveAspectRatio", "xMidYMid slice");
      svgEmbed.id = "svgTest";
    }
  }, []);


  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        // position: "relative",s
        overflow:'visible'
      }}>
      {svg && strattoSvgType === "3d" ? (
        <InteractiveFloorStratto svg={svg} scaleZoom={scaleZoom}/>
      ) : (
        <InteractiveFloorplanStratto2D svg={stratto2dSvg} />
      )}
    </div>
  );
};
