import { Box } from "@mui/material";
import { ReactComponent as StrattoHomeLogo } from "../../../assets/icons/StrattoHomeLogo.svg";
import { useNavigate } from "react-router-dom";

export default function HomeIcon() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "absolute",
        top: "30px",
        left: "30px",
      }}
    >
      <StrattoHomeLogo
        style={{
          width: "20%",
          height: "20%",
          cursor: "pointer",
        }}
        onClick={() => navigate("/stratto")}
      />
    </Box>
  );
}
