import { Grid, Box, useMediaQuery, Stack } from "@mui/material";
import { ReactComponent as Alberca } from "../../assets/icons/Alberca.svg";
import { ReactComponent as Fitness } from "../../assets/icons/Fitness.svg";
import { ReactComponent as DogPark } from "../../assets/icons/DogPark.svg";
import { ReactComponent as Asadores } from "../../assets/icons/Asadores.svg";
import { ReactComponent as CoffeeBar } from "../../assets/icons/CoffeeBar.svg";
import { ReactComponent as SocialBar } from "../../assets/icons/SocialBar.svg";
import { ReactComponent as CoWorking } from "../../assets/icons/CoWorking.svg";
import { ReactComponent as GameRoom } from "../../assets/icons/GameRoom.svg";
import { ReactComponent as ReplayIcon } from "../../assets/icons/ReplayIcon.svg";

import {
  ButtonStratto,
  HomeIcon,
} from "../ComponentsUtilities";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import AmenitiesAreaModal from "./AmenitiesAreaModal";
import styled from "@emotion/styled";
import { useAppSelector } from "../../hooks";

export default function AmenitiesArea() {
  const mobile = useMediaQuery("(max-width:601px)");
  const BUTTON_ICON_MAPPING: any = {
    "Alberca" : <Alberca />,
    "Fitness center": <Fitness />,
    "Dog Park":<DogPark />,
    "Asadores":<Asadores />,
    "Coffee Bar":<CoffeeBar />,
    "Social Bar":<SocialBar />,
    "Co-Working":<CoWorking />,
    "Game room":<GameRoom />
  }
  const [open, setOpen] = useState(false);
  const [iconButtons, setIconButtons] = useState<any>([])
  const [selectedAmentity, setSelectedAmentity] = useState<any>(null)
  const listButtonData = useAppSelector((state)=>state.availabilityStratto?.views[4].components[0].iconList)

  useLayoutEffect(()=>{
    const tempData: any = []
    listButtonData?.map((item: any)=>{
      return tempData.push({...item, icon: BUTTON_ICON_MAPPING[item?.name]})
    })
    setIconButtons(tempData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listButtonData])


  const handleClose = () => setOpen(!open);
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        width: "100%",
        height: mobile ? "unset" : "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {!mobile && <HomeIcon />}
      <div style={{ padding: "0 70px" }}>
        <div>
          <p
            style={{
              color: "rgba(255, 255, 255, 1)",
              textAlign: mobile ? "center" : "unset",
            }}
          >
            AMENIDADES
          </p>
        </div>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {iconButtons.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={2.4}>
                  <ButtonComponent onClick={()=> {

                    setSelectedAmentity(item)
                    setOpen(true)}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ margin: "0 22px" }}><img src={item?.iconImage} alt="icon"/></div>
                      <p style={{ fontWeight: "700", fontSize: "16px" }}>
                        {item?.name}
                      </p>
                    </div>
                  </ButtonComponent>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </div>
      {mobile ? (
        <div
          style={{
            position: mobile ? "unset" : "absolute",
            bottom: "24px",
            padding: "30px 0px",
          }}
        >
          <Stack direction="row" spacing={2}>
            <ButtonStratto name="Depas" path="" />
            <ButtonStratto name="Amenidades" path="stratto" />
          </Stack>
        </div>
      ) : (
        <div style={{ position: "absolute", bottom: "24px" }}>
          <ButtonStratto name="Depas" path="availability" />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          bottom: "24px",
          left: "30px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/amenities-broll")}
      >
        {!mobile && <ReplayIcon />}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: mobile ? "97px" : "20px",
          right: "0",
          display: open ? "none" : "unset",
        }}
      >
        {/* <UtilitiesStratto /> */}
      </div>
      <AmenitiesAreaModal onClose={handleClose} open={open} amentityData={selectedAmentity}/>
    </div>
  );
}
const ButtonComponent = styled.div`
  background: rgba(242, 242, 242, 1);
  height: 88px;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(0px);
  cursor: pointer;
  :hover {
    /* background: gray; */
    box-shadow: 4px 4px 10px gray;
    transform: translateY(-10px);
    transition: all .3s ease-in-out;
  }
`;
