import {
  useAppDispatch,
  useAppSelector,
  useAvailabilityFilter,
  useCurrentLoading,
} from "../../hooks";
import { ReactSVG } from "react-svg";
import { useCallback, useRef, useState } from "react";
import { FloorplanstrattoCard } from "../FloorplanCard";

import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import {
  setCurrentStrattoAvailabilityModal,
  setStrattoLevel,
} from "../../store/todoStratto-actions";
import {
  useStrattoFilter,
  useStrattoFiltersValues,
  useStrattoUnits,
} from "../../hooks/hooksStratto";


export const fills: { [key: string]: string } = {
  available: "#B4FFEE",
  reserved: "#FFE7B6",
  taken: "#C0C7FF",
  sold: "#FFC0C0",
  nonavailable: "#C4C4C4",
};

interface Props {
  svg: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const InteractiveFloorplanStratto2D = ({ svg }: Props) => {
  const strattoUnits = useStrattoUnits();
  const [bedroomFilter, bathroomFilter, floorplanFilter] =
    useStrattoFiltersValues();

  const dispatch = useAppDispatch();
  const currentSvg = useRef(null);
  const hoveredElementRef = useRef<any>(null);
  const selectedElementRef = useRef<any>(null);
  const lockElementRef = useRef<any>(null);
  const lockUnitRef = useRef<any>(null);
  const [mousePosition, setMousePosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const availability = useAvailabilityFilter();
  const level = useAppSelector((state) => state.availabilityStratto.level);
  const { levels } = useStrattoFilter();

  const loading = useCurrentLoading();


  const renderSVG = useCallback(() => {
    const svgEmbed: any = document.getElementsByClassName("injected-svg")[0];
    if (svgEmbed) {
      svgEmbed.setAttribute("preserveAspectRatio", "xMidYMid slice");
      svgEmbed.classList.remove("injected-svg");
      svgEmbed.classList.add("injected-svg-2D-stratto");
      svgEmbed.id = "svgTest2D";
    }

    document.getElementById("svgTest2D")?.removeAttribute("style");
    document
      .querySelectorAll(".st2")
      .forEach((item: any) => item.setAttribute("style", `stroke: lightGray`));
    document
      .querySelectorAll(".mk")
      .forEach((item: any) => item.setAttribute("style", `opacity: 0 !important`));
    

    // const filteredUnits =
    //   !bathroomFilter &&
    //   !bedroomFilter &&
    //   !floorplanFilter &&
    //   !levelFilter
    //     ? strattoUnits
    //     : strattoUnits.filter(
    //         (unit: any) =>
    //           unit.bathrooms === bathroomFilter ||
    //           unit.bedrooms === bedroomFilter ||
    //           unit.floor_plan_code === floorplanFilter ||
    //           unit.floor === levelFilter
    //       );

    strattoUnits
      .filter(
        (unit: any) =>
          (bathroomFilter === null ||
            bathroomFilter === undefined ||
            unit.bathrooms === bathroomFilter) &&
          (bedroomFilter === null ||
            bedroomFilter === undefined ||
            unit.bedrooms === bedroomFilter) &&
          (floorplanFilter === null ||
            floorplanFilter === undefined ||
            unit.floor_plan_code?.toLowerCase() === floorplanFilter) &&
          (level === null ||
            level === undefined ||
            unit.floor === level)
      )
      ?.forEach((unit: any) => {
        const currentLevelNode = document.getElementById(`Level${unit.floor}`);

        if (currentLevelNode) {
          currentLevelNode?.classList.add("floor");
          if (
            unit?.floor?.toString() === level?.toString() ||
            currentLevelNode?.innerHTML.includes("circle")
          ) {
            currentLevelNode?.classList.remove("floor");
            currentLevelNode?.setAttribute(
              "style",
              `fill:transparent; opacity:1`
            );
          }
        }

        const { status = "available", name } = unit;
        const floorplan = document.getElementById(`U-${name}`);
        const floorplanPolygon = document.getElementById(`P-${name}`);
        const floorplanCircleNumber = document.getElementById(`M-${name}`);
        const floorplanCode = document.getElementById(`T-${name}`);

        if (floorplanPolygon) {
          // floorplanPolygon?.classList.remove("st2");
          floorplanPolygon.setAttribute(
            "style",
            `fill:transparent; opacity: 1; stroke: #B4FFEE`
          );
          if (status === availability) {
            floorplanPolygon?.classList.remove("st2");
            floorplanPolygon.setAttribute(
              "style",
              `fill:${fills[availability]}; opacity: 0.5`
            );
          }

          if (lockUnitRef.current === name) {
            floorplanPolygon?.classList.remove("st2");
            floorplanPolygon.setAttribute(
              "style",
              `fill:${fills[status || "available"]}; opacity: 0.5`
            );
          }
        }

        if (floorplanCircleNumber) {
          floorplanCircleNumber.setAttribute(
            "style",
            `fill:${fills[status || "available"]}; opacity: 1;`
          );
        }

        if (floorplanCode) {
          floorplanCode.setAttribute("style", `fill: black; opacity: 1;`);
        }

        if (floorplan) {
          floorplan.addEventListener("mousedown", (e: any) => {
            const { clientX: x, clientY: y } = e;
            setMousePosition({ x, y });
            lockElementRef.current = true;
            lockUnitRef.current = name;
            hoveredElementRef.current = unit;
            selectedElementRef.current = unit;
          });
          floorplan.addEventListener(
            "mouseenter",
            (e: any) => {
              if (!lockElementRef.current) {
                const { clientX: x, clientY: y } = e;
                setMousePosition({ x, y });
                if (floorplanPolygon) {
                  floorplanPolygon?.classList.remove("st2");
                  floorplanPolygon.setAttribute(
                    "style",
                    `fill:${fills[status || "available"]}; opacity: 0.5`
                  );
                }
                hoveredElementRef.current = unit;
              }
            },
            false
          );
        }
      });
  }, [availability, bathroomFilter, bedroomFilter, floorplanFilter, level, strattoUnits]);

  const mobile = useMediaQuery("(max-width:1260px)");

  const [personName, setPersonName] = useState<string[]>([]);

  const defauValue = personName.length === 0 ? ["0"] : personName;

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  if (!svg) return null;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
      onMouseEnter={() => {
        if (!lockElementRef.current) {
          setMousePosition(null);
          hoveredElementRef.current = null;
        }
      }}>
      {!mobile && (
        <div
          style={{
            width: "110px",
            height: "100%",
            position: "absolute",
            zIndex: "100",
            right: "0",
            backgroundColor: "#D9D9D9",
            overflowY:'auto'
          }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            // sx={{
            //   height: "100%",
            // }}
            >
            <p
              style={{
                fontWeight: "500",
              }}>
              LEVEL
            </p>
            {levels
              ?.map(({ value: inputValue }, i: any) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <Chip
                      key={inputValue}
                      sx={{
                        backgroundColor:
                          inputValue === level ? "#B2FFEE" : "#F6F6F6",
                        border:
                          inputValue === level ? "2px solid#46949C" : "none",
                        width: "80px",
                        height: "36px",
                        margin: "6px 0",
                        fontSize: "16px",
                        fontWeight: inputValue === level ? "600" : "",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor:
                            inputValue !== level ? "#ebe8e8" : "#B2FFEE",
                        },
                      }}
                      label={inputValue === 0 ? "PB" : inputValue}
                      onClick={() => dispatch(setStrattoLevel(inputValue))}
                    />
                  </div>
                );
              })
              .reverse()}
          </Stack>
        </div>
      )}

      {mobile && (
        <FormControl
          sx={{
            m: 1,
            width: 150,
            position: "absolute",
            bottom: "0",
            right: "0",
            margin: "0",
            padding: "0",
          }}>
          <Select
            id="menu2d"
            value={defauValue}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => {
              return <Chip label={`level: ${selected}`} />;
            }}
            MenuProps={MenuProps}>
            {levels.map(({ value: name }) => {
              return (
                <MenuItem
                  key={name}
                  value={name}
                  onClick={() => dispatch(setStrattoLevel(name))}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}>
          <CircularProgress sx={{ color: "#3948FF" }} />
        </Box>
      ) : (
        <ReactSVG
          ref={currentSvg}
          afterInjection={(err, svg) => {
            if (svg) renderSVG();
          }}
          src={`${svg}?type=svg`}
          style={{
            height: "100%",
            width: "100%",
            display: "block",
            backgroundColor: "#ffffff",
          }}
          className="svg-container"
        />
      )}

      {mousePosition?.x && mousePosition?.y && hoveredElementRef.current && (
        <FloorplanstrattoCard
          x={mousePosition?.x}
          y={mousePosition?.y}
          selectedFloorplan={hoveredElementRef.current}
          clearSelectedFloor={() => {
            hoveredElementRef.current = null;
            selectedElementRef.current = null;
            lockElementRef.current = false;
            lockUnitRef.current = null;
            setMousePosition(null);
          }}
          onClick={() => {
            hoveredElementRef.current = null;
            lockElementRef.current = false;
            lockUnitRef.current = null;
            setMousePosition(null);
            dispatch(setCurrentStrattoAvailabilityModal(true));
          }}
        />
      )}
    </div>
  );
};
