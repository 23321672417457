import { useMemo } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// hooks
export const useStrattoUnits = () => {
  const availabilityStratto = useAppSelector(state=>state.availabilityStratto)
  const {views} = availabilityStratto
  const strattoUnits:any = views[2]?.components[0]?.apartments?.reduce((acc: any, current:any)=>[...acc, ...current?.units], [])
  

  return strattoUnits
};
export const useCurrentVideoStratto = () => {
  const currentVideo = useAppSelector(
    (state) => state.availabilityStratto.currentVideo
  );
  return useMemo(() => currentVideo, [currentVideo]);
};

export const useCurrentTypeStratto = () => {
  const currentVideoType = useAppSelector(
    (state) => state.availabilityStratto.currentVideoType
  );
  return useMemo(() => currentVideoType, [currentVideoType]);
};

export const useViewsStratto = () => {
  const views = useAppSelector((state) => state.availabilityStratto.views);
  return useMemo(() => views, [views]);
};

export const useCurrentLocationStratto = () => {
  const currentLocation = useAppSelector(
    (state) => state.availabilityStratto.currentLocation
  );
  return useMemo(() => currentLocation, [currentLocation]);
};

export const useCurrentViewStratto = () => {
  const currentView = useAppSelector(
    (state) => state.availabilityStratto.currentView
  );
  return useMemo(() => currentView, [currentView]);
};

export const useCurrentStrattoAvailabilityModal = () => {
  const currentModalToggle = useAppSelector(
    (state) => state.availabilityStratto.toggleAvailabilityModal
  );
  return useMemo(() => currentModalToggle, [currentModalToggle]);
};

export const useStrattoSvgType = () => {
  const currentSvgType = useAppSelector(
    (state) => state.availabilityStratto.svgType
  );
  return useMemo(() => currentSvgType, [currentSvgType]);
};

export const useStrattoAvailabilityFilter = () => {
  const availabilityStrattoFilter: any = useAppSelector(
    (state) => state.availabilityStratto.availability
  );
  return useMemo(() => availabilityStrattoFilter, [availabilityStrattoFilter]);
};

export const useStrattoFilter = ()=>{
  const currentUnits = useStrattoUnits()
  const bedrooms = useMemo(() => {
    const numberOfBedrooms = new Set<number>();
    const numberOfBathrooms = new Set<number>();
    currentUnits?.forEach((unit: any) => {
      numberOfBathrooms.add(unit.bathrooms);
    });
    currentUnits?.forEach((unit: any) => {
      numberOfBedrooms.add(unit.bedrooms);
    });
    let returnOptions:any = [];
    // eslint-disable-next-line array-callback-return
    Array.from(numberOfBedrooms)?.map((bedroom: number) => {
      Array.from(numberOfBathrooms).map((bathroom: number) => {
        return returnOptions.push({
          text: `${bedroom.toString()} \\ ${bathroom.toString()}`,
          value: `${bedroom}-${bathroom}`,
        });
      });
    });
    return returnOptions;
  }, [currentUnits]);
  const bathrooms = useMemo(() => {
    const numberOfBathrooms = new Set<number>();
    currentUnits?.forEach((unit: any) => {
      numberOfBathrooms.add(unit.bathrooms);
    });
    return Array.from(numberOfBathrooms)?.map((bathroom) => ({
      text: bathroom.toString(),
      value: bathroom,
    }));
  }, [currentUnits]);
  const floorplanTypes = useMemo(
    () =>
      {
        const unitFloorplanType = new Set();
        currentUnits?.forEach((unit: any) => {
          unitFloorplanType.add(unit.floor_plan_code)
        });
        return Array.from(unitFloorplanType)?.map((type: any) => ({
          text: type.toString(),
          value: type,
        }));
      },
    [currentUnits]
  );
  // const prices = useMemo(
  //   () =>
  //     availability.filters.prices?.map(
  //       (
  //         {
  //           min,
  //           max,
  //           currency,
  //         }: { min: number; max: number; currency: string },
  //         i: number
  //       ) => {
  //         let optionText = `${numberFormat.format(min)} - ${numberFormat.format(
  //           max
  //         )} ${currency}`;
  //         if (i === 1) {
  //           optionText = `Menos de ${numberFormat.format(max)} ${currency}`;
  //         }
  //         if (i === availability.filters.prices.length - 1) {
  //           optionText = `Más de ${numberFormat.format(min)} ${currency}`;
  //         }
  //         return {
  //           text: optionText,
  //           value: i === 0 ? 0 : `${min} - ${max}`,
  //         };
  //       }
  //     ) || [],
  //   [availability.filters.prices, numberFormat]
  // );
  // const areas = useMemo(
  //   () =>
  //     availability.filters.areas?.map(
  //       (
  //         {
  //           min,
  //           max,
  //           areaMetric = "m2",
  //         }: { min: number; max: number; areaMetric: string },
  //         i: any
  //       ) => {
  //         let optionText = `${min} - ${max} ${areaMetric}`;
  //         if (i === 1) {
  //           optionText = `Menos de ${max} ${areaMetric}`;
  //         }
  //         if (i === availability.filters.areas.length - 1) {
  //           optionText = `Más de ${min} ${areaMetric}`;
  //         }
  //         return {
  //           text: optionText,
  //           value: i === 0 ? 0 : `${min} - ${max}`,
  //         };
  //       }
  //     ) || [],
  //   [availability.filters.areas]
  // );
  const levels = useMemo(() => {
    const levelNumbers = new Set<string>();
    currentUnits?.forEach((unit: any) => levelNumbers.add(unit.floor));

    return Array.from(levelNumbers)
      ?.map((floor) => ({
        text: `Level ${floor}`,
        value: parseInt(floor, 10),
      }))
      .sort((a: any, b: any) => a.value - b.value);
  }, [currentUnits]);

  return {
    levels, bedrooms, bathrooms, floorplanTypes
  }
}

export const useStrattoFiltersValues = () => {
  const availabilityStratto = useAppSelector((state) => state.availabilityStratto);
  const bathrooms = useMemo(
    () => availabilityStratto.bathrooms,
    [availabilityStratto.bathrooms]
  );
  const bedrooms = useMemo(
    () => availabilityStratto.bedrooms,
    [availabilityStratto.bedrooms]
  );
  const prices = useMemo(() => availabilityStratto.prices, [availabilityStratto.prices]);
  const areas = useMemo(() => availabilityStratto.areas, [availabilityStratto.areas]);
  const floorplanType = useMemo(
    () => availabilityStratto.floorplanType,
    [availabilityStratto.floorplanType]
  );
  const level = useMemo(() => availabilityStratto.level, [availabilityStratto.level]);
  return [bedrooms, bathrooms, floorplanType, prices, areas, level];
};
