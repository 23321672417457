import React from "react";
import { useStyles } from "./AmenitiesBrollStyle";
import { ButtonStratto, HomeIcon } from "../ComponentsUtilities";
import { useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
export default function AmenitiesBroll() {
  const navigate = useNavigate();
  const classes = useStyles();
  const availabilityStratto = useAppSelector(
    (state) => state.availabilityStratto
  );

  return (
    <div className={classes.containerBroll}>
      <video
        controls
        autoPlay
        className={classes.videoScreen}
        onEnded={() => navigate("/amenities-area")}>
        <source
          src="https://athum.com/360/768/videos/AmenitiesBRoll-Web.mp4"
          type="video/mp4"
        />
      </video>
      <HomeIcon />
      <div className={classes.buttonBroll}>
        {availabilityStratto?.views[4]?.components[0].buttons.map(
          (button: any, index: number) => (
            <ButtonStratto
              key={index}
              marginRight="33px"
              name={button.text}
              path={button.goTo === "view-3" ? "floorplans" : "amenities-area"}
            />
          )
        )}
      </div>
    </div>
  );
}
