import { Box, Grid, Modal, Stack, useMediaQuery } from "@mui/material";
import {  UtilitiesStratto } from "../ComponentsUtilities";
import { makeStyles } from "@mui/styles";
import React, { useMemo, useState } from "react";
// Import Swiper React components
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import type { Swiper as SwiperType } from "swiper";
// import { ReactComponent as ThreeDirection } from "../../assets/icons/3D.svg";
import { ReactComponent as Minimap } from "../../assets/icons/Minimap.svg";
import { ReactComponent as VideoTour } from "../../assets/icons/VideoTour.svg";


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setStrattoCurrentApartment } from "../../store/todoStratto-actions";
import { useStrattoUnits, useViewsStratto } from "../../hooks/hooksStratto";

export const useStyles = makeStyles({
  contentModal: {
    padding: "20px",
  },
  title: {
    fontWeight: "300",
    fontSize: "14px",
    lineHeight: "17px",
    margin: "0",
    padding: "0",
  },
  info: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "29px",
  },
  thTable: {
    width: "auto",
    padding: "4px 0",
  },
  thTableAll: {
    width: "100%",
    '& tr:not(:first-child):hover':{
      background:'rgba(86, 176, 192, 0.2)',
      cursor: 'pointer'
    }
  },
  videoScreen: {
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: "15px",
    objectFit:'cover',

    "@media (max-width: 640px)": {
      height: "100%",
      width: "unset",
    },
    "@media (max-width: 1200px)": {
      height: "100%",
      width: "unset",
    },
  },
});

// const InfoMobileView = () => {
//   const classes = useStyles();
//   const mobileMini = useMediaQuery("(max-width:320px)");
//   const mobile = useMediaQuery("(max-width:601px)");
//   const tabletMini = useMediaQuery("(max-width:820px)");
//   const setWidthText = () => {
//       if(mobileMini){
//           return '235px'
//       }
//       if(mobile) {
//           return '270px'
//       }
//       return '390px'
//   }
//   return (
//     // <Box width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
//     //         <Box width={'100%'} textAlign={'left'} padding={tabletMini? '0 80px': '0 40px'}>
//     //             <div>
//     //                 <Typography fontWeight={300} fontSize={'16px'} color={'rgba(0, 0, 0, 1)'} >AMENIDADES</Typography>
//     //             </div>
//     //             <Box marginTop={'20px'} >
//     //                 <Alberca/>
//     //             </Box>
//     //             <Box marginTop={'20px'}>
//     //                 <Typography fontWeight={900} fontSize={'28px'} color={'rgba(0, 0, 0, 1)'}>Alberca</Typography>
//     //             </Box>
//     //             <Box marginTop={'15px'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
//     //                 <Typography fontSize={'20px'} fontWeight={300} color={'rgba(0, 0, 0, 1)'}>Nivel:</Typography>
//     //                 <Typography marginLeft={'6px'} fontSize={'20px'} fontWeight={700} color={'rgba(0, 0, 0, 1)'}>40</Typography> 
//     //             </Box>
//     //             <Box marginTop={'20px'} width={setWidthText()}>
//     //                 <Typography fontSize={'14px'} fontWeight={400} color={'rgba(0, 0, 0, 1)'}>
//     //                     Admira la ciudad desde las alturas disfrutando un día soleado al aire libre compartiendo una deliciosa comida y buenos momentos.
//     //                 </Typography>
//     //             </Box>
//     //             <Box marginTop={'20px'}>
//     //                 <ul style={{fontSize:'14px', fontWeight:'400', color:'rgba(0, 0, 0, 1)'}}>
//     //                     <li style={{marginTop:'20px'}}>Asadores y mesas</li>
//     //                     <li style={{marginTop:'20px'}}>Wellness area</li>
//     //                     <li style={{marginTop:'20px'}}>Lounge area para tus reuniones</li>
//     //                     <li style={{marginTop:'20px'}}>Terraza de 300 m2</li>
//     //                 </ul>
//     //             </Box>              
//     //         </Box>
//     // </Box>
//     <Box sx={{backgroundColor:'white'}} width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
//         <Grid item xs={0} lg={4} xl={6} width={'100%'}>
//             <div>
//                     <Box sx={{ marginBottom: "14px" }}>
//                     <h4 className={classes.title}>UNIDAD:</h4>
//                     <span className={classes.info}>4001</span>
//                     </Box>

//                     <Box sx={{ marginBottom: "14px" }}>
//                     <h4 className={classes.title}>Renta mensual</h4>
//                     <span className={classes.info}>$15,000 MXN</span>
//                     </Box>

//                     <Stack direction={"row"} sx={{ marginBottom: "14px" }}>
//                     <Box sx={{ marginRight: "80px" }}>
//                         <h4 className={classes.title}>Planta tipo:</h4>
//                         <span className={classes.info}>Studio 1</span>
//                     </Box>
//                     <Box>
//                         <h4 className={classes.title}>Nivel: </h4>
//                         <span className={classes.info}>40</span>
//                     </Box>
//                     </Stack>
//                     <Stack direction={"row"} sx={{ marginBottom: "14px" }}>
//                     <Box sx={{ marginRight: "80px" }}>
//                         <h4 className={classes.title}>Recámaras: </h4>
//                         <span className={classes.info}>Studio </span>
//                     </Box>
//                     <Box>
//                         <h4 className={classes.title}>Baños: </h4>
//                         <span className={classes.info}>1</span>
//                     </Box>
//                     </Stack>
//                     <Box sx={{ marginBottom: "14px" }}>
//                     <h4 className={classes.title}>Área Total: </h4>
//                     <span className={classes.info}>37.64 m2</span>
//                     </Box>
//             </div>
//             <table className={classes.thTableAll}>
//                     <tr>
//                     <th className={classes.thTable}>
//                         <p  style={{color:'#56B0C0'}}>
//                             Número de unidad
//                         </p>
//                     </th>
//                     <th className={classes.thTable}>Nivel</th>
//                     <th className={classes.thTable}>Renta Mensual</th>
//                     </tr>
//                     <tr>
//                     <td>Peter</td>
//                     <td>Griffin</td>
//                     <td>Griffin</td>
//                     </tr>
//                     <tr>
//                     <td>Lois</td>
//                     <td>Griffin</td>
//                     <td>Griffin</td>
//                     </tr>
//                     <tr>
//                     <td>Lois</td>
//                     <td>Griffin</td>
//                     <td>Griffin</td>
//                     </tr>
//                     <tr>
//                     <td>Lois</td>
//                     <td>Griffin</td>
//                     <td>Griffin</td>
//                     </tr>
//                     <tr>
//                     <td>Lois</td>
//                     <td>Griffin</td>
//                     <td>Griffin</td>
//                     </tr>
//                     <tr>
//                     <td>Lois</td>
//                     <td>Griffin</td>
//                     <td>Griffin</td>
//                     </tr>
//                     <tr>
//                     <td>Lois</td>
//                     <td>Griffin</td>
//                     <td>Griffin</td>
//                     </tr>
//                     <tr>
//                     <td>Lois</td>
//                     <td>Griffin</td>
//                     <td>Griffin</td>
//                     </tr>
//             </table>
//         </Grid> 
//     </Box>
//   )
// }

interface AvailabilityModalProps {
    onClose?: any;
    open?: any;
  }

export const DEFAULT_VIDEO_TOUR = "https://athum.com/360/768/videos/S1-Web.mp4"


export default function AvailabilityModal({onClose, open} :AvailabilityModalProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch()
  const strattoUnits = useStrattoUnits()
  const strattoViews = useViewsStratto()
  const currentApartment = useAppSelector((state)=>state.availabilityStratto.selectedApartment)
  const currentFloorApartment: any = useMemo(()=>{
    return strattoViews[2]?.components[0]?.apartments?.find((apartment: any)=>apartment?.floor_plan_code?.toLowerCase() === currentApartment?.floor_plan_code?.toLowerCase())
  },[currentApartment?.floor_plan_code, strattoViews])



  const sameFloorplanTypeUnits = useMemo(()=>{
    return strattoUnits?.filter((item: any)=> item.floor_plan_code?.toLowerCase() === currentApartment?.floor_plan_code?.toLowerCase())
  },[currentApartment?.floor_plan_code, strattoUnits])

  const currentApartmentSliderMedia = useMemo(() => {
    return [
      currentFloorApartment?.sections?.gallery?.[0],
      { imageUri: currentFloorApartment?.floor_plan_mini_map },
      ...currentFloorApartment?.sections?.gallery,
    ];
  }, [currentFloorApartment?.floor_plan_mini_map, currentFloorApartment?.sections?.gallery]);

  const currentApartmentMedia = useMemo(() => {
    return [
      { imageUri: currentFloorApartment?.sections?.tour_video },
      { imageUri: currentFloorApartment?.floor_plan_mini_map },
      ...currentFloorApartment?.sections?.gallery,
    ];
  }, [currentFloorApartment?.floor_plan_mini_map, currentFloorApartment?.sections?.gallery, currentFloorApartment?.sections?.tour_video]);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const desktopSmall = useMediaQuery("(max-width:1500px)");
  const desktopNormal = useMediaQuery("(max-width:1870px)");
  const tablet = useMediaQuery("(max-width:1180px)");
  const tabletSmall = useMediaQuery("(max-width:1024px)")
  const tabletMini = useMediaQuery("(max-width:820px)");
  const mobile = useMediaQuery("(max-width:601px)");
  const mobileMini = useMediaQuery("(max-width:320px)");
  const setWidthImg = () => {
      if(tabletMini){
          return '100%'
      }
      if(tabletSmall){
          return '400px'
      }
      if(tablet) {
          return '565px'
      }
      if(desktopSmall){
          return '800px'
      }
      if(desktopNormal) { 
          // return '785px'
          return '860px'
      }
      return '1176px'
  }
  const setHeightImg = () => {
      if(tabletMini){
          return '50%'
      }
      if(tablet) {
          return '535px'
      }
      if(desktopSmall){
          return '535px'
      }
      if(desktopNormal) {
          return '751px'
      }
      return '768px'
  }
  const setButtonWidth = () => {
      if(mobile){
          return '100%'
      }
      if(tabletMini) {
          return '177px'
      }
      if(tablet) {
          return '150px'
      }
      return '220px'
  }
  const setButtonHeight = () => {
      if(tabletMini) {
          return '46px'
      }
  }
  const fontSizeButton = () => {
      if(tablet) {
          return '15px'
      }
      return '20px'
  }
  const slidesPerView = () => {
      if(tabletSmall){
          return 3
      }
      if(tablet){
          return 4
      }
      if(desktopSmall){
          return 6
      }
      if(desktopNormal) {
          return 5
      }
      return 5
  }
  const closeButtonRight = () => {
      if(tabletMini) { 
          return '1%'
      }
      if(desktopNormal){
          return '-63px'
      }
      return '-70px'
  }
  const marginSpace = () =>{
      if(tabletMini) {
          return '0px'
      }
      if(desktopNormal){
          return '50px'
      }
      return '55px'
  }
  const setFontSizeIcon = () => {
      if(mobile){
          return '19px'
      }
      if(tabletMini) {
          return '40px'
      }
      return 
  }
  const setUtilitiesStrattoRight = () => {
      if (mobileMini) {
          return 'unset'
      }
      if(tabletMini){
          return '-2px';
      }
      return '-79px'
  }

  const setUtilitiesStrattoBottom =() =>{
      if(mobileMini){
          return '311px'
      }
      if(tabletMini){
          return '58px'
      }
      return '32px'
  }

  const setWidthModal = () => {
    if(tabletMini) {
        return '100%'
    }
    if(tablet) {
        return '84%'
    }
    return '90%'
  }

  return (
    <div
    // style={{width:'100%', height:'100%'}}
    >
      <Modal
        open={open}
        onClose={onClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        aria-labelledby="customized-dialog-title">
        <Box width={"100%"} height={"100%"}>
          <Box
            bgcolor={tabletMini ? "" : "rgba(255, 255, 255, 1)"}
            position={"absolute"}
            top={tabletMini ? "unset" : "50%"}
            left={tabletMini ? "unset" : "49%"}
            sx={{
              transform: tabletMini ? "unset" : "translate(-50%, -50%)",
              outline: "none",
            }}
            borderRadius={"15px"}
            padding={tabletMini ? "0px" : "25px"}
            // width={tabletMini ? '100%': 'unset'}
            width={setWidthModal()}
            height={tabletMini ? "100%" : "unset"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={tabletMini ? "center" : "unset"}
              height={tabletMini ? "100%" : "unset"}>
              <Box
                width={tabletMini ? "100%" : "unset"}
                height={tabletMini ? "100%" : "unset"}>
                <Box
                  width={setWidthImg()}
                  height={setHeightImg()}
                  borderRadius={tabletMini ? "" : "15px"}
                  overflow={"hidden"}
                  position={"relative"}>
                  <Swiper
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                          ? thumbsSwiper
                          : null,
                    }}
                    modules={[FreeMode, Navigation, Thumbs, Pagination]}
                    pagination={tabletMini ? true : false}>
                    {currentApartmentMedia.map((item: any, index: number) => {
                      if (!index)
                        return (
                          <SwiperSlide>
                            <video controls className={classes.videoScreen}>
                              <source
                                src={
                                  item?.imageUri
                                    ? item?.imageUri
                                    : DEFAULT_VIDEO_TOUR
                                }
                                type="video/mp4"
                              />
                            </video>
                          </SwiperSlide>
                        );
                      return (
                        <>
                          <SwiperSlide>
                            <img
                              width={"100%"}
                              height={"100%"}
                              // height={tabletMini? '50%' :'100%'}
                              style={{ objectFit: "contain", backgroundColor:"#616161" }}
                              src={item?.imageUri}
                              alt="nature"
                            />
                          </SwiperSlide>
                        </>
                      );
                    })}
                    {/* {
                                        tabletMini && 
                                        <SwiperSlide>
                                            <InfoMobileView/>
                                        </SwiperSlide>
                                    } */}
                  </Swiper>
                </Box>

                {tabletMini && (
                  <Box
                    position={"absolute"}
                    bottom={0}
                    sx={{ backgroundColor: "white", bottom: "0" }}
                    height={"50%"}
                    width={"100%"}
                    padding-left={"25px"}>
                    <div style={{ marginTop: "50px", paddingLeft: "25px" }}>
                      <Box sx={{ marginBottom: "14px" }}>
                        <h4 className={classes.title}>UNIDAD:</h4>
                        <span className={classes.info}>
                          {currentApartment?.name}
                        </span>
                      </Box>

                      <Box sx={{ marginBottom: "14px" }}>
                        <h4 className={classes.title}>Renta mensual</h4>
                        <span className={classes.info}>
                          $
                          {Number(currentApartment?.price).toLocaleString(
                            "en-US"
                          )}{" "}
                          MXN
                        </span>
                      </Box>

                      <Stack direction={"row"} sx={{ marginBottom: "14px" }}>
                        <Box sx={{ marginRight: "80px" }}>
                          <h4 className={classes.title}>Planta tipo:</h4>
                          <span className={classes.info}>
                            {currentApartment?.floor_plan_code?.toUpperCase()}
                          </span>
                        </Box>
                        <Box>
                          <h4 className={classes.title}>Nivel: </h4>
                          <span className={classes.info}>
                            {currentApartment?.floor}
                          </span>
                        </Box>
                      </Stack>
                      <Stack direction={"row"} sx={{ marginBottom: "14px" }}>
                        <Box sx={{ marginRight: "180px" }}>
                          <h4 className={classes.title}>Recámaras: </h4>
                          <span className={classes.info}>
                            {currentApartment?.bedrooms}
                          </span>
                        </Box>
                        <Box>
                          <h4 className={classes.title}>Baños: </h4>
                          <span className={classes.info}>
                            {currentApartment?.bathrooms}
                          </span>
                        </Box>
                      </Stack>
                      <Box sx={{ marginBottom: "14px" }}>
                        <h4 className={classes.title}>Área Total: </h4>
                        <span
                          className={
                            classes.info
                          }>{`${currentApartment?.area} m2`}</span>
                      </Box>
                    </div>
                  </Box>
                )}
              </Box>
              {!tabletMini && (
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}>
                  <Box marginLeft={marginSpace()} width={"100%"}>
                    <Grid item xs={0} lg={4} xl={6}>
                      <div>
                        <Box sx={{ marginBottom: "14px" }}>
                          <h4 className={classes.title}>UNIDAD:</h4>
                          <span className={classes.info}>
                            {currentApartment?.name}
                          </span>
                        </Box>

                        <Box sx={{ marginBottom: "14px" }}>
                          <h4 className={classes.title}>Renta mensual</h4>
                          <span className={classes.info}>
                            $
                            {Number(currentApartment?.price).toLocaleString(
                              "en-US"
                            )}{" "}
                            MXN
                          </span>
                        </Box>

                        <Stack direction={"row"} sx={{ marginBottom: "14px" }}>
                          <Box sx={{ marginRight: "180px" }}>
                            <h4 className={classes.title}>Planta tipo:</h4>
                            <span className={classes.info}>
                              {currentApartment?.floor_plan_code?.toUpperCase()}
                            </span>
                          </Box>
                          <Box>
                            <h4 className={classes.title}>Nivel: </h4>
                            <span className={classes.info}>
                              {currentApartment?.floor}
                            </span>
                          </Box>
                        </Stack>
                        <Stack direction={"row"} sx={{ marginBottom: "14px" }}>
                          <Box sx={{ marginRight: "180px" }}>
                            <h4 className={classes.title}>Recámaras: </h4>
                            <span className={classes.info}>
                              {currentApartment?.bedrooms}
                            </span>
                          </Box>
                          <Box>
                            <h4 className={classes.title}>Baños: </h4>
                            <span className={classes.info}>
                              {currentApartment?.bathrooms}
                            </span>
                          </Box>
                        </Stack>
                        <Box sx={{ marginBottom: "14px" }}>
                          <h4 className={classes.title}>Área Total: </h4>
                          <span
                            className={
                              classes.info
                            }>{`${currentApartment?.area} m2`}</span>
                        </Box>
                      </div>
                      <div style={{ maxHeight: "35vh", overflow: "auto" }}>
                        <table className={classes.thTableAll}>
                          <tr>
                            <th
                              style={{ color: "#56B0C0" }}
                              className={classes.thTable}>
                              Número de unidad
                            </th>
                            <th className={classes.thTable}>Nivel</th>
                            <th className={classes.thTable}>Renta Mensual</th>
                          </tr>
                          {sameFloorplanTypeUnits?.map((unit: any) => {
                            return (
                              <tr
                                onClick={() => {
                                  dispatch(setStrattoCurrentApartment(unit));
                                }}>
                                <td>{unit?.name}</td>
                                <td>{unit?.floor}</td>
                                <td>
                                  ${Number(unit?.price).toLocaleString("en-US")}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>

                      {/* <Stack direction="row" sx={{ marginTop: "20px" }} spacing={2}>
                                            <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#56B0C0",
                                                width: "220px",
                                                height: "56px",
                                                borderRadius: "16px",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                            }}
                                            >
                                            Reserva
                                            </Button>
                                            <Button
                                            variant="outlined"
                                            sx={{
                                                color: "#56B0C0",
                                                border: "1px solid #56B0C0",
                                                width: "220px",
                                                height: "56px",
                                                borderRadius: "16px",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                            }}
                                            >
                                            Agenda tu visita
                                            </Button>
                                        </Stack> */}
                    </Grid>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={tabletMini ? "center" : "unset"}
              marginTop={"19px"}>
              {!tabletMini && (
                <Box
                  width={setWidthImg()}
                  height={"89px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  overflow={"auto"}>
                  <Swiper
                    navigation={true}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={slidesPerView()}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    style={{ width: "100%", height: "100%" }}>
                    {currentApartmentSliderMedia?.map(
                      (item: any, index: number) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="swiperWrapper">
                              <img
                                width={"100%"}
                                height={"100%"}
                                src={item.imageUri}
                                alt="nature"
                                style={{ objectFit: "cover" }}
                              />
                              {!!(index === 0) && (
                                <div className="overlayImage">
                                  <VideoTour />
                                </div>
                              )}
                              {!!(index === 1) && (
                                <div className="overlayImage">
                                  <Minimap />
                                </div>
                              )}
                            </div>
                          </SwiperSlide>
                        );
                      }
                    )}
                  </Swiper>
                </Box>
              )}
              <Box
                display={"flex"}
                alignItems={"center"}
                marginLeft={marginSpace()}
                position={tabletMini ? "absolute" : "unset"}
                zIndex={tabletMini ? "999" : "0"}
                bottom={tabletMini ? "15px" : ""}
                justifyContent={tabletMini ? "center" : "unset"}
                width={tabletMini ? "100%" : "unset"}>
                <button
                  onClick={() =>
                    window.open(
                      "https://moradaunoapp.com.mx/#/inquilinos/registro?whitelabel=RIGA",
                      "_blank"
                    )
                  }
                  style={{
                    backgroundColor: "rgba(86, 176, 192, 1)",
                    padding: "20px 0",
                    fontSize: fontSizeButton(),
                    fontWeight: "700",
                    borderRadius: "15px",
                    color: "rgba(255, 255, 255, 1)",
                    cursor: "pointer",
                    height: setButtonHeight(),
                    width: setButtonWidth(),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid rgba(86, 176, 192, 1)",
                    marginLeft: mobile ? "10px" : "0px",
                  }}>
                  Reserva
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://rigabosques.com/contact.html",
                      "_blank"
                    )
                  }
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    padding: "20px 0",
                    fontSize: fontSizeButton(),
                    fontWeight: "700",
                    borderRadius: "15px",
                    cursor: "pointer",
                    marginLeft: "15px",
                    height: setButtonHeight(),
                    width: setButtonWidth(),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid rgba(86, 176, 192, 1)",
                    color: "rgba(86, 176, 192, 1)",
                    marginRight: mobile ? "10px" : "0px",
                  }}>
                  Agenda tu visita
                </button>
              </Box>
            </Box>
            <div onClick={onClose}>
              <Box
                position={"absolute"}
                width={"48px"}
                height={"48px"}
                bgcolor={tabletMini ? "" : "rgba(255, 255, 255, 1)"}
                top={tabletMini ? "1%" : "0"}
                right={closeButtonRight()}
                sx={{ cursor: "pointer" }}
                borderRadius={"50%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                zIndex={100}>
                <CloseIcon sx={{ fontSize: setFontSizeIcon() }} />
              </Box>
            </div>
            <Box
              position={"absolute"}
              right={setUtilitiesStrattoRight()}
              bottom={setUtilitiesStrattoBottom()}
              left={mobileMini ? "64px" : "unset"}
              zIndex={999}>
              <UtilitiesStratto />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}


